@import 'src/scss/palette';
@import 'src/scss/variables';
@import 'src/scss/mixins';

.table-header-cell {
    color: $medium-gray;
    align-items: center;
    flex-grow: 1;

    &__icon {
        margin-right: 8px;
        display: flex;
    }
    &__info {
        display: flex;
        align-items: center;
    }

    &__title {
        @include cut-text;
    }
}
