@import 'src/scss/palette';
@import 'src/scss/functions';
@import 'src/scss/variables';

.nearby-spots {
    max-width: 1350px;
    width: 95%;
    margin: 0 auto snif-grid(4);

    .car-slide {
        display: flex;
        margin: 0 snif-grid(-1);

        .listing-preview {
            width: calc(100% / 3);
            min-width: calc(100% / 3);
            margin-bottom: 0 !important;
        }

        &.car-slide-ssr {
            overflow: auto;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                height: 8px;
            }
        }
    }
}

.nearby-spots-mobile {
    margin-bottom: snif-grid(4);

    .listing-preview.home-page {
        &:nth-last-child(1) {
            margin-right: 0;
        }
    }

    .in-row {
        overflow-x: auto;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            height: 4px;
        }
    }
}

.mobile-photo-gallery {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $white;
    width: 100%;
    height: 100%;
    z-index: 130;

    .gallery-header {
        position: sticky;
        top: 0;
        z-index: 120;
        background-color: $white;
        display: flex;
        align-items: center;
        height: 56px;

        .back-ico {
            display: flex;
            left: 0;
            padding: snif-grid(4) snif-grid(4) snif-grid(4) snif-grid(2);
            cursor: pointer;
            z-index: 125;
        }

        p {
            position: absolute;
            left: 0;
            width: 100vw;
            padding: snif-grid(2);
            text-align: center;
        }
    }

    .preloader {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 300px;
        z-index: 10;
    }

    .gallery-photos {
        overflow: hidden;
        height: calc(100dvh - 138px);

        .photos-box {
            padding-bottom: snif-grid(2);

            .spot-detail-photos-submitted {
                bottom: 28px;
            }

            .photos-item {
                width: 100%;
                height: 300px;
                object-fit: cover;
                object-position: center;
            }

            .spot-detail-photos-caption {
                font-size: 14px;
                line-height: 21px;
                margin: snif-grid(0.5) snif-grid(2) 0;
            }
        }
    }
}

.single-view-photo {
    position: relative;

    .carousel__photo-container {
        padding-top: 100%;
    }

    .photos-item {
        width: 100%;
        height: auto;
    }

    .photos-submitted-box {
        position: fixed;
        bottom: 90px;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;

        .photos-submitted {
            padding: 2px 16px;
            background: $fog-gray;
            backdrop-filter: blur(2px);
            border-radius: 6px;
            margin-bottom: 0;
        }
    }
}

.spot-view-page-warning {
    .warning-spot-photos__container {
        width: calc(100% - 64px);
        max-width: 1350px;
        margin: 0 auto !important;
        margin-bottom: snif-grid(-1) !important;
        padding: snif-grid(4) 0 0 !important;

        @media #{$min-width-mobile} {
            > div {
                max-width: 1350px;
                margin: 0;
            }
        }

        @media #{$max-width-mobile} {
            width: 100%;
            margin: snif-grid(1) 0 !important;
            padding: snif-grid(2) !important;
        }
    }
}

.spot-view-page {
    display: flex;

    &.container {
        max-width: 1350px;
        width: calc(100% - 64px);

        @media #{$min-width-mobile} {
            padding-top: snif-grid(3);
        }
    }

    .price {
        .price-box {
            box-shadow: 0px 0px snif-grid(2) rgba(0, 0, 0, 0.15);
            border-radius: snif-grid(0.5);
            padding: 8px 12px;
            display: inline-block;
        }

        .ok-icon {
            width: 24px;
            height: 24px;
            color: $green;
            margin: 0 snif-grid(2) 0 0;
        }

        @media #{$min-width-mobile} {
            .price-box {
                padding: 12px 24px;
            }
        }
    }

    .spot-detail-block {
        width: calc(100% - 360px);
    }

    .desktop-book-block {
        position: sticky;
        top: 120px;
        height: 100%;
        height: fit-content;
        margin-top: snif-grid(2);
        margin-left: snif-grid(3);

        &.spot-top-bar-test {
            top: 177px;
        }

        &__content {
            width: 360px;
            padding: snif-grid(3);
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
            border-radius: snif-grid(2);

            > div {
                width: 312px;
            }

            .book-price {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .book-rating {
                    display: flex;

                    .star-icon {
                        width: 20px;
                        height: 20px;
                        margin-right: snif-grid(1);
                    }

                    .ratings-count {
                        margin-right: snif-grid(1);
                    }

                    .comments-count {
                        color: $deep-gray;
                    }
                }
            }
        }

        .user-member-banner {
            margin: snif-grid(3) 0 0;
        }

        &.disabled {
            .desktop-book-block__content {
                background-color: $danger-lite;
                border-radius: snif-grid(2);
                box-shadow: none;
            }
        }
    }

    .desktop-header {
        width: 100%;
        margin-top: snif-grid(2);

        .header-info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .info-left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .spot-location {
                    margin-right: snif-grid(3);

                    svg {
                        margin-right: snif-grid(1);
                    }
                }
            }

            .spot-core-action {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .spot-top-badge {
                    position: relative;
                    margin: 0 snif-grid(2) 0 0;
                    height: fit-content;
                }

                .spot-fav-fab__fab {
                    position: relative;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: fit-content;
                    margin-right: snif-grid(2);
                }
            }
        }

        .spot-header-detail {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: snif-grid(3);

            .spot-core-detail {
                display: flex;
                justify-content: flex-start;

                .head-info-box {
                    background-color: $snow-white;
                    padding: snif-grid(0.5) snif-grid(2);
                    margin-right: snif-grid(1);
                    border-radius: 50px;

                    svg {
                        width: 20px;
                        height: 16px;
                        margin-right: snif-grid(1);
                        vertical-align: text-bottom;
                    }

                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                }
            }
        }

        .spot-photos-block {
            display: flex;
            position: relative;

            .show-all-photo {
                position: absolute;
                right: snif-grid(2);
                bottom: snif-grid(2);
            }

            .desktop-photo {
                background-color: $snow-white;
                padding-top: $photo-height;
                height: 0;
                cursor: pointer;
                position: relative;

                .preloader {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 50;
                }

                .photos-item {
                    object-position: center;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .img-big {
                flex: 2;
                margin-right: snif-grid(2);

                .desktop-photo {
                    height: 100%;
                    border-top-left-radius: snif-grid(2);
                    border-bottom-left-radius: snif-grid(2);

                    .photos-item {
                        border-top-left-radius: snif-grid(2);
                        border-bottom-left-radius: snif-grid(2);
                    }
                }

                &.full-img {
                    width: 100%;
                    margin-right: 0;
                    height: 532px;
                    border-radius: 16px;
                    overflow: hidden;

                    .desktop-photo {
                        padding-top: 532px;
                    }

                    img {
                        height: 532px;
                    }
                }
            }

            .img-sml {
                flex: 1;
                display: flex;
                flex-direction: column;

                .desktop-photo {
                    &:nth-child(1) {
                        margin-bottom: snif-grid(2);
                        border-top-right-radius: snif-grid(2);

                        .photos-item {
                            border-top-right-radius: snif-grid(2);
                        }
                    }

                    &:nth-child(2) {
                        border-bottom-right-radius: snif-grid(2);

                        .photos-item {
                            border-bottom-right-radius: snif-grid(2);
                        }
                    }
                }
            }
        }
    }
}

.dis-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    background-color: $danger-lite;

    &.top-bar {
        width: 100vw;
        height: 56px;
        position: fixed;
        top: 0;
        z-index: 175;

        &.banner {
            top: 50px;
        }
    }

    .dis-cal-ico {
        color: $danger;
        margin-right: snif-grid(2);
    }

    .title {
        color: $danger;
    }

    .dis-more-info {
        margin-left: snif-grid(2);
    }
}

.head-container {
    padding: 0;

    &.banner {
        margin-top: 50px;
    }

    .head {
        margin: 0 2rem;
        flex-direction: row-reverse;

        .info {
            padding: 0 0;
            position: relative;

            .head-row-title {
                margin: 0;

                .col-md-12 {
                    padding: 0;
                }
            }
        }

        .mobile-carousel {
            padding: 0;
            position: relative;

            .spot-main-photo {
                width: 100%;
                height: 0;
                padding-top: 256px;

                .carousel__photo-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding-top: 0;
                }

                .preloader {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 50;
                }

                &.carou-ssr {
                    height: 256px;
                    padding-top: 0;

                    .img.carousel-spot-inner {
                        width: 100%;
                        height: 256px;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                &.placeholder-photo {
                    height: 256px !important;
                    padding-top: 0 !important;
                    cursor: default !important;
                }
            }

            .spot-main-action {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: snif-grid(2);
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .close-box {
                    width: fit-content;
                }

                .actions {
                    width: fit-content;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;

                    .spot-top-badge {
                        margin: 0 snif-grid(2) 0 0 !important;
                        position: relative;
                    }

                    .spot-fav-fab__fab {
                        margin: 0 snif-grid(2) 0 0 !important;
                        position: relative;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}

.spot-detail-photos-submitted,
.spot-detail-photos-counter {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;

    .photos-text {
        padding: 2px 12px;
        color: $white;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(2px);
        border-radius: 6px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        white-space: nowrap;
    }
}

.spot-detail-photos-counter {
    bottom: snif-grid(2);
}

.spot-detail-photos-submitted {
    bottom: snif-grid(1.5);
    padding-right: snif-grid(1.5);
    justify-content: flex-end;

    .photos-text {
        padding: 2px 16px;
    }
}

.ask-container {
    .ask-row {
        margin-top: 2rem;
        a {
            color: $green;
            cursor: pointer;
        }
    }
}

.container {
    &.rules,
    &.price {
        ul {
            padding-left: 2.3rem;

            li {
                margin-bottom: snif-grid(2);
            }
        }
        ul,
        .cancel-text {
            font-size: 2rem;
        }
        .row-field {
            padding: 1rem 0;
            .field {
                font-size: 2rem;
            }
        }
    }
    &.calendar {
        .calendar-title {
            margin: 3rem 1rem 1rem;
        }

        max-width: 100%;

        .row {
            margin: 0;
        }

        .col-md-12 {
            padding: 0;
        }
    }

    &.spinner {
        max-width: 100%;

        .row {
            height: calc(100vh - 50px);
            align-items: center;
            .spinner {
                display: inline-block;
                background-image: url('../../assets/svg/SvgSpinner.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 100px;
                height: 100px;
            }
        }
    }
}

.sdp-render-sub-info {
    @media #{$min-width-mobile} {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% - 324px);
    }

    .mobile-sub-info {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media #{$min-width-mobile} {
            padding-top: snif-grid(3);
        }

        .sub-star {
            width: 18px;
            height: 18px;
            margin-right: snif-grid(1);
        }

        .sub-rating {
            font-weight: 500;
            color: $deep-gray;
        }

        .dot-separator {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $soft-gray;
            margin: 0 snif-grid(1);
        }

        .sub-reviews {
            position: relative;
            margin-right: snif-grid(2);
            cursor: pointer;
        }
    }

    .mobile-sub-badges {
        display: flex;
        align-items: center;
        margin-bottom: snif-grid(3);
        padding: 0 snif-grid(2);

        @media #{$min-width-mobile} {
            margin-bottom: 0;
            padding: snif-grid(3) 0 0;
        }

        .spot-top-badge {
            position: relative;
            margin: 0;
        }

        > div {
            &:nth-child(1) {
                margin-left: 0;

                & > div:nth-child(1) {
                    margin-left: 0;
                }
            }
        }
    }
}

.mobile-host-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .host-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-right: snif-grid(1);
    }

    .host-text {
        .host-name {
            position: relative;
            cursor: pointer;
        }
    }
}

.placeholder-photo {
    cursor: default !important;
    background-color: $fog-gray;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 36px;
        height: 36px;
        color: $medium-gray;
    }
}

.spot-view-report-block {
    margin-top: snif-grid(4);
}

.spot-view-margin {
    margin-bottom: snif-grid(6);
}

.full-screen-modal {
    max-width: 100%;

    .modal-content {
        .carousel {
            height: 80vh;
        }

        .modal-header {
            padding: 0;
            padding-right: 1rem;
        }
    }
}

.desktop-photo-backdrop.show {
    opacity: 0.75;
}

.dscrp {
    white-space: pre-wrap;
}

.dotted-underline {
    position: absolute;
    width: 100%;
    bottom: -4px;
    left: 0;
    border-bottom: 1px dashed $deep-gray;
}

.before-book-warning-modal {
    @media #{$min-width-mobile} {
        min-width: 560px;
    }

    .book-warning-action {
        margin-top: snif-grid(6);

        @media #{$min-width-mobile} {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    @media #{$max-width-mobile} {
        .sniff-button {
            width: 100%;
        }
    }

    .book-warning-info-section {
        display: flex;
        margin-bottom: snif-grid(3);

        svg {
            margin-right: snif-grid(2);
        }

        .info-section-right {
            width: calc(100% - 40px);
        }
    }
}

.spot-view-page-footer {
    @media #{$min-width-mobile} {
        .snif-section .container {
            max-width: 1350px;
        }
    }
}

.memberships-modal-spot-details {
    &__header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: $danger-lite;
        border-radius: 10px 10px 0 0;
    }

    &__close {
        position: absolute;
        padding: snif-grid(2);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 5;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__content {
        position: relative;
        padding: snif-grid(1) snif-grid(1) snif-grid(2);
        text-align: center;
    }

    &__thumbnail {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        margin: 0 auto;

        img {
            width: 80px;
            height: 80px;
            border-radius: 16px;
            object-position: center;
            object-fit: cover;
        }

        svg {
            position: absolute;
            bottom: -8px;
            right: -8px;
        }
    }

    &__cta {
        margin-top: snif-grid(5);
        color: $dark-gray !important;
        background-color: $yellow !important;
    }
}

.sniffpass-modal-spot-details {
    &__header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: $green-bg-subs;
        border-radius: 10px 10px 0 0;
    }

    &__close {
        position: absolute;
        padding: snif-grid(2);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 5;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__content {
        position: relative;
        padding: snif-grid(1) snif-grid(1) snif-grid(2);
        text-align: center;
    }

    &__thumbnail {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        margin: snif-grid(3) auto 0;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    &__cta {
        margin-top: snif-grid(5);
    }
}

.scroll-modal-content {
    &_close {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

@media #{$max-width-mobile} {
    .container {
        max-width: calc(100% - 32px);

        &.rules,
        &.price {
            ul {
                padding-left: 19px;

                li {
                    margin-bottom: snif-grid(1);
                }
            }
            ul,
            .cancel-text {
                font-size: 16px;
            }
            .row-field {
                padding: snif-grid(0.5) 0;
                .field {
                    font-size: 16px;
                }
            }
        }
    }

    .head-container {
        max-width: 100%;
        margin: 0;

        .head {
            margin: 0;
            flex-direction: row;
            padding-top: 0;

            .info,
            .mobile-sub-info,
            .mobile-host-info {
                padding: 0 16px;
                width: 100%;
            }
        }
    }

    .ask-container {
        .ask-row {
            a {
                font-size: $h6-size;
            }
        }
    }
}

@media screen and (min-width: 0) and (max-width: 325px) {
    .head-container {
        .head {
            .info {
                padding: 0 16px;
            }

            .mobile-carousel {
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 326px) and (max-width: 580px) {
    .head-container {
        .head {
            .info {
                padding: 0 16px;
            }

            .mobile-carousel {
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 580px) and (max-width: 767px) {
    .head-container {
        .head {
            .info {
                margin: 0 16px;
                padding: 0;
            }

            .mobile-carousel {
                width: 768px;
                padding: 0;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 989px) {
    .head-container {
        .head {
            flex-direction: row;
            .info {
                margin: 0 15%;
                padding: 0;
            }

            .mobile-carousel {
                width: 100%;

                .carousel .carousel-spot-inner.img {
                    border-radius: snif-grid(1);
                }
            }
        }
    }
}

@media screen and (min-width: 990px) and (max-width: 1199px) {
    .head-container {
        .head {
            .info {
                width: calc(100% - 600px);
            }

            .mobile-carousel {
                width: 600px;
                height: 425px;

                .carousel .carousel-spot-inner.img {
                    border-radius: snif-grid(1);
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .head-container {
        .head {
            .info {
                width: calc(100% - 750px);
            }

            .mobile-carousel {
                width: 750px;
                height: 475px;

                .carousel .carousel-spot-inner.img {
                    border-radius: snif-grid(1);
                }
            }
        }
    }
}

@media #{$max-width-mobile} {
    .spot-view-page {
        &.container {
            max-width: 100%;
            width: 100%;
        }

        .desktop-book-block {
            display: none;
        }

        .spot-detail-block {
            width: 100%;
        }

        .head-info {
            margin-left: 0;
            margin-right: 0;

            .head-info-box {
                display: flex;
                align-items: center;
                margin-bottom: snif-grid(3);
            }
        }

        .mobile-sub-info {
            margin-bottom: snif-grid(3);
        }

        .mobile-host-info {
            margin-bottom: snif-grid(3);
        }
    }

    .dis-info {
        padding: snif-grid(2);
    }

    body {
        width: 100vw;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 325px) {
    .container {
        h2 {
            font-size: 20px !important;
            line-height: 24px !important;
        }
    }
}
