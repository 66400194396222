/* base colors from style guide */
$green: #3aa648;
$green-gray: #b1c6b5;
$blue: #4f96c4;
$yellow: #f2c94c;
$orange: #e8ab66; /* aka sherbet */
$red: #ca3237;

$danger: #ce7003;
$danger-lite: #fcf4db;
$warning: #d7860e;

$white: #ffffff;
$black: #000000;
$snow-white: #f5f5f5;
$off-white: #fafafa;

$host-gray: #f3f4f2;
$alice-grey: #f4f7fe;
$fog-gray: #e7e7e7;
$soft-gray: #cbcbcb;
$light-gray: #a4a4a4;
$medium-gray: #787878;
$deep-gray: #484848;
$dark-gray: #323232;

$bg-green: #e8ffe8;
$bg-green-2: #f1ffec;
$snow-green: #7aff8229;
$green-100: #d4efd5;
$green-200: #bbe1bd;
$green-300: #8cd090;
$green-400: #51b85d;
$green-500: $green;
$green-600: #2e8a3a;
$green-700: #236e2b;
$green-800: #17531d;
$green-900: #062907;
$green-10-opct: #3aa6481a;
$green-bg-host: #e9fbe3;
$green-bg-host-completion: #dbffdb;
$green-bg-subs: rgba(212, 239, 213, 0.5);

$blue-100: #dff7f7;
$blue-200: #bde6e9;
$blue-300: #97d5e7;
$blue-400: #73b6d6;
$blue-500: $blue;
$blue-600: #376c97;
$blue-700: #20426a;
$blue-800: #10264c;
$blue-900: #02042d;
$blue-line: #226dff;
$blue-bg: #ebf9fc;
$blue-bg-visit: #f6fdff;

$success-100: #d6fab4;
$success-200: #c3faa0;
$success-300: #a9ee86;
$success-400: #a3ea81;
$success-500: #50b820;
$success-600: #589340;
$success-700: #3f702e;
$success-800: #264c1c;
$success-900: #0d2909;

$warning-10: #fdefdd;
$warning-20: #f9d09f;
$warning-100: #fff5e7;
$warning-400: $warning;
$warning-600: #caa13d;
$warning-800: #7a501e;
$warning-900: #52280f;

$yellow-info-bg: #fffae2;
$snow-yellow: #fdf8e6;
$yellow-100: #fffeb2;
$yellow-200: #fef677;
$yellow-300: #fae769;
$yellow-400: #f6d85a;
$yellow-500: $yellow;
$yellow-600: #caa13d;
$yellow-700: #a2792e;
$yellow-800: #7a501e;
$yellow-900: #52200f;
$yellow-20: rgba(242, 200, 76, 0.2);
$yellow-dsb: #f3d885;

$red-100-10: #f8c4b41a;
$red-100: #f8c4b4;
$red-200: #e79b90;
$red-300: #cf6661;
$red-400: #bf4242;
$red-500: $red;
$red-600: #851215;
$red-700: #65090b;
$red-800: #4d0304;
$red-900: #360000;

$fb-primary: #3475dc;
$fb-bg: #eff8ff;

$py-bg: #f0f2fc;

/* color maps */
// map-get($map-green, '500'),

$map-green: (
    '100': $green-100,
    '200': $green-200,
    '300': $green-300,
    '400': $green-400,
    '500': $green-500,
    '600': $green-600,
    '700': $green-700,
    '800': $green-800,
    '900': $green-900,
);

$map-blue: (
    '100': $blue-100,
    '200': $blue-200,
    '300': $blue-300,
    '400': $blue-400,
    '500': $blue-500,
    '600': $blue-600,
    '700': $blue-700,
    '800': $blue-800,
    '900': $blue-900,
);

$map-yellow: (
    '100': $yellow-100,
    '200': $yellow-200,
    '300': $yellow-300,
    '400': $yellow-400,
    '500': $yellow-500,
    '600': $yellow-600,
    '700': $yellow-700,
    '800': $yellow-800,
    '900': $yellow-900,
);

$map-red: (
    '100': $red-100,
    '200': $red-200,
    '300': $red-300,
    '400': $red-400,
    '500': $red-500,
    '600': $red-600,
    '700': $red-700,
    '800': $red-800,
    '900': $red-900,
);
