@import 'src/scss/palette';
@import 'src/scss/functions';

.spot-next-prev {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1354px;
    width: calc(100% - 64px);
    margin: 0 auto;
    padding: snif-grid(1) snif-grid(1.5);

    @media #{$max-width-mobile} {
        width: 100%;
    }

    a {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: snif-grid(1);
        color: $deep-gray;
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        transition: color 300ms;

        &:hover {
            color: $green;
        }

        &.disabled {
            color: $soft-gray;
            cursor: not-allowed;
        }

        &:nth-child(1) {
            padding-left: 0;
        }

        &:nth-last-child(1) {
            padding-right: 0;
        }

        svg {
            width: 10px;
            height: 10px;
        }
    }
}

.spot-next-prev-container {
    position: sticky;
    top: 56px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    background-color: $white;
    transition: top 300ms ease-in-out;
    z-index: 50;

    &.full-top {
        top: 0;
    }

    @media #{$max-width-mobile} {
        &.is-top-bar-signup {
            top: 113px;
        }
    }

    @media #{$min-width-mobile} {
        top: 80px;
        box-shadow: none;
        border-bottom: 1px solid $fog-gray;

        .spot-next-prev {
            padding: snif-grid(1.5) 0;
        }
    }
}
