@import './palette';
@import './functions';
@import './mixins/buttons';
@import './mixins/typorgaphy';
@import './font-mixin';

// Only for one line text
@mixin cut-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin reservation-status($negative: $medium-gray) {
    &_past,
    &_expired,
    &_canceled,
    &_canceled_by_admin,
    &_declined {
        color: $negative;
    }

    &_upcoming,
    &_active {
        color: $green;
    }

    &_pending {
        color: $orange;
    }
}

@mixin sniff-input {
    border: 1px solid $fog-gray;
    background-color: $white;
    border-radius: snif-grid(0.5);
    min-height: snif-grid(6);
    height: fit-content;
    font-size: 16px;
    font-family: 'Work Sans';
    line-height: 22px;
    color: $dark-gray;
    padding: snif-grid(1.5);
    outline: none;
    transition: all 200ms;

    &::placeholder {
        font-size: inherit;
        color: $light-gray;
    }

    &:hover {
        border-color: $soft-gray;
    }

    &:focus {
        border-color: $green-300;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    }

    &:disabled {
        border-color: $soft-gray;
        background-color: $snow-white;
        color: $deep-gray;
    }

    &.warning {
        border-color: $yellow;
        color: $dark-gray;
    }

    &.error {
        border-color: $red-200;
        color: $dark-gray;
    }

    &.success {
        border-color: $success-500;
        color: $dark-gray;
    }
}
