@import '../../scss/functions';
@import '../../scss/palette';

.carousel {
    & &__spot-photo-container {
        padding-top: $photo-height;

        > .splide {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .splide__track {
                height: 100%;
            }
        }

        .spot-detail-photos-counter {
            position: absolute;
            bottom: snif-grid(2);
            width: 100%;
            display: flex;
            justify-content: center;

            .photos-text {
                padding: 2px 12px;
                color: $white;
                background: rgba(0, 0, 0, 0.5);
                backdrop-filter: blur(2px);
                border-radius: 6px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                white-space: nowrap;
            }
        }
    }

    .mysplide__arrows {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 0;

        .mysplide__arrow {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: snif-grid(1);
            width: 32px;
            height: 32px;
            background-color: rgba(255, 255, 255, 0.85);
            border: none;
            border-radius: 50%;
            transition: all 300ms;

            svg {
                width: 12px;
                height: 12px;
                color: $deep-gray;
            }

            &--prev {
                left: 8px;
            }

            &--next {
                right: 8px;
            }

            &:hover {
                background-color: $white;
            }
        }
    }

    .carousel-icon-actions {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        top: 4px;
        right: 4px;
        z-index: 9;

        .spot-share-button__box {
            display: flex;
            padding: snif-grid(1);
            color: $white;
            cursor: pointer;
        }

        .spot-fav-fab__fab {
            position: unset;
            top: unset;
            right: unset;
            padding: snif-grid(1);
            color: $white;
            z-index: unset;
        }
    }
}

.raw-img-delete-photo {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: snif-grid(1);
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    svg {
        width: 12px;
        height: 12px;
    }
}

.car-ssr-req-container {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}
