@import 'src/scss/palette';
@import 'src/scss/functions';

.spot-detail-my-badge {
    display: flex;
    width: 360px;
    margin-top: snif-grid(3);
    padding: snif-grid(2);
    border-radius: 16px;

    > div {
        width: calc(100% - 24px);
        padding-left: snif-grid(2);

        p {
            white-space: pre-wrap;
        }
    }
}
