@import './variables/buttons';
@import './variables/typography';

$snif-grid-step: 8px;

// MEDIA
$mobile-size: 768px;
$min-width-mobile: '(min-width: #{$mobile-size})';
$max-width-mobile: '(max-width: #{$mobile-size - 1})';

// FOONTS
$root-font-size: 12px;

$font_falling_sky: 'Falling Sky';

$h1-multiplier: 5.333333333;
$h2-multiplier: 4;
$h3-multiplier: 2.666;
$h4-multiplier: 2.166666666;
$h5-multiplier: 1.666666666;
$h6-multiplier: 1.5;

$h1-size: 5.333333333rem;
$h2-size: 4rem;
$h3-size: 2.666rem;
$h4-size: 2.166666666rem;
$h5-size: 1.666666666rem;
$h6-size: 1.5rem;
$h7-size: 1rem;

$png-ico-size: 3rem;
$mobile-png-ico-size: 2rem;

$hr-margin: 2rem;
$hr-mobile-margin: 1rem;

// PHOTOS
$photo-height: 64%;

// Main menu
$mobile-main-menu-height: 56px;
$mobile-main-menu-height-listings: 72px;

// filters header menu
$filtres-header-menu: 70px;

// PAGE
$page-margin: 1rem;

// Ico
$ico-size: 36px;

$header-height: 80px;
$sm-header-height: $mobile-main-menu-height;

$smart-banner-height: 84px;
