@import 'src/scss/functions';
@import 'src/scss/palette';

.head-ico {
    width: $png-ico-size;
    height: $png-ico-size;
    margin-right: snif-grid(2);
    display: inline-block;

    &.fenced {
        background: url('../../assets/svg/SvgFenced.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &.ruler {
        background: url('../../assets/svg/SvgRuler.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.head-row-title {
    // margin-left: -10px;

    .head-title {
        margin-top: 6rem;
        margin-bottom: 0;
        padding-right: 15px;

        @media screen and (min-width: $mobile-size) and (max-width: 959px) {
            margin-top: 1rem;
        }
    }
}

.head-location {
    svg {
        position: relative;
        left: -2px;
        margin-right: snif-grid(1);
    }
}

.head-location,
.head-row-title,
.head-info {
    .col-md-12 {
        padding: 0;
    }
}

.head-info {
    font-size: 16px;

    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .head-info-box {
            background-color: $snow-white;
            padding: snif-grid(0.5) snif-grid(2);
            margin-right: snif-grid(1);
            margin-bottom: 1rem;
            border-radius: 50px;
        }
    }
}

@media screen and (max-width: $mobile-size) {
    .head-ico {
        width: 20px;
        height: 16px;
        margin-right: snif-grid(1);
    }

    .head-row-title {
        margin: auto;

        .head-title {
            margin-top: snif-grid(2);
        }
    }

    .head-location {
        margin: auto;
        padding: 16px 0 24px;
    }
}

@media screen and (max-width: 325px) {
    .head-row-title .head-title {
        font-size: 22px !important;
        line-height: 24px !important;
    }

    .head-info {
        padding-bottom: 1rem;
        font-size: 14px;
    }
}
