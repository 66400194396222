@import 'src/scss/palette';
@import 'src/scss/functions';

.spot-badge-offer-membership {
    display: flex;
    align-items: center;
    margin-left: snif-grid(2);
    cursor: pointer;

    @media #{$min-width-mobile} {
        margin-left: snif-grid(3);
    }

    svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }

    span {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        color: $medium-gray;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: underline;
        text-decoration-style: dashed;
        text-underline-offset: 4px;
    }
}
