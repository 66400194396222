@import 'src/scss/font-mixin';
@import 'src/scss/functions';
@import 'src/scss/palette';

.fencing {
    .fence-details {
        .enclosure,
        .fence-height {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            svg {
                margin-right: snif-grid(2);
            }
        }

        .percentage {
            svg {
                margin-right: snif-grid(0.5);
                width: 16px;
                height: 16px;
            }
        }

        .enclosure,
        .fence-height,
        .percentage,
        .fence-gap,
        .fence-type,
        .fence-detail {
            width: 100%;

            &.break-word {
                word-wrap: break-word;
            }

            .read-more {
                cursor: pointer;
            }
        }
    }
}

@media screen and #{$min-width-mobile} {
    .fencing {
        .fence-details {
            display: flex;

            > div {
                width: 50%;
            }
        }
    }
}

@media screen and #{$max-width-mobile} {
}
